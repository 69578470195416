import React, { MouseEventHandler } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import {
  DialogClose,
  DialogContent,
  DialogOverlay,
} from "components/Dialog/DialogPrimitives";
import ConnectAccountForm, {
  ConnectAccountFormProps,
} from "components/authenticate/ConnectAccountForm";

const CLASS_BASE = "connect-account-form-dialog";
export const ConnectAccountFormDialogClassName = {
  OVERLAY: `${CLASS_BASE}-overlay`,
  CONTENT: `${CLASS_BASE}-content`,
} as const;

export interface ConnectAccountFormDialogProps extends ConnectAccountFormProps {
  className?: string;
  onCloseClick?: MouseEventHandler<HTMLButtonElement>;
  open?: boolean;
}

/**
 * This implementation does not include a trigger intentionally.
 * We launch this modal conditionally based on authentication logic.
 */
export const ConnectAccountFormDialog = ({
  onCloseClick,
  open = false,
  ...connectAccountFormProps
}: ConnectAccountFormDialogProps) => {
  const cls = ConnectAccountFormDialogClassName;

  return (
    <Dialog.Root modal open={open}>
      <Dialog.Portal>
        <DialogOverlay className={cls.OVERLAY} />
        <DialogContent className={cls.CONTENT}>
          <DialogClose onClick={onCloseClick} />
          <ConnectAccountForm {...connectAccountFormProps} />
        </DialogContent>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default ConnectAccountFormDialog;
