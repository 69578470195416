import React, { RefAttributes } from "react";
import styled, { css, keyframes } from "styled-components";
import * as Dialog from "@radix-ui/react-dialog";
import {
  Button,
  Icon,
  IconName,
  colors,
  media,
} from "@literati/public-ui-shared-react";

export const overlayShow = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
`;

export const DialogOverlay = styled(Dialog.Overlay)`
  position: fixed;
  inset: 0;
  background: ${colors.black};
  opacity: 0.5;
  animation: ${overlayShow} 300ms ease;
`;

export const contentShow = keyframes`
  from {
    right: 50%;
    bottom: 25%;
    transform: translate(50, 25%);
  }
  to {
    right: 50%;
    bottom: 50%;
    transform: translate(50%, 50%);
  }
`;

export const centerDesktopContentStyles = css`
  left: unset;
  right: 50%;
  transform: translate(50%, 50%);
`;

export const DialogContent = styled(Dialog.Content)`
  position: fixed;
  padding: 1.5rem 1.5rem 4rem;
  right: 1.25rem;
  left: 1.25rem;
  bottom: 50%;
  transform: translate(0, 50%);
  max-height: calc(100dvh - 4rem);
  overflow: auto;
  overflow-x: hidden;

  background: ${colors.white};
  border-radius: 6px;

  ${media.md} {
    ${centerDesktopContentStyles};
    max-width: 585px;
    animation: ${contentShow} 300ms ease;
  }
`;

const StyledClose = styled(Dialog.Close)`
  position: sticky;
  float: right;
  top: 0;
  right: 0;
  width: 16px;
  height: 16px;
  min-height: 16px;
  background: white;
  z-index: 2;
`;

export const DialogClose = (
  props: Dialog.DialogCloseProps & RefAttributes<HTMLButtonElement>
) => {
  return (
    <StyledClose asChild {...props}>
      <Button variant="unstyled">
        <Icon name={IconName.X} />
      </Button>
    </StyledClose>
  );
};
