import { useContext, useEffect, useRef } from "react";
import { AdminContext } from "contexts/AdminProvider";
import Script from "next/script";

const GoogleLoginButton = ({
  onToken,
  onClick,
  disableAutoTap = false,
  width = 260,
}) => {
  const {
    settings: { GOOGLE_OAUTH_CLIENT_ID },
  } = useContext(AdminContext);

  const handleCredentialResponse = (response) => {
    onToken({ token: response.credential, provider: "google" });
  };

  const googleButton = useRef(null);
  const clickListener = () => onClick?.("google");

  const renderButton = () => {
    width = Math.min(
      width,
      googleButton?.current?.parentElement?.clientWidth || width
    );
    window.google.accounts.id.renderButton(
      googleButton?.current,
      {
        text: "continue_with",
        theme: "outline",
        shape: "pill",
        size: "large",
        width,
        logo_alignment: "center",
        click_listener: clickListener,
      } // customization attributes
    );

    !disableAutoTap && window.google.accounts.id.prompt();
  };

  const initialize = () => {
    window.google.accounts.id.initialize({
      client_id: GOOGLE_OAUTH_CLIENT_ID,
      callback: handleCredentialResponse,
      context: "use",
    });
    renderButton();
  };

  useEffect(() => {
    if (window.google) {
      initialize();
      renderButton();
    }
  }, []);

  return (
    <>
      <Script
        src="https://accounts.google.com/gsi/client"
        onLoad={initialize}
        strategy={"afterInteractive"}
      />
      <div ref={googleButton} />
    </>
  );
};

export default GoogleLoginButton;
