import styled from "styled-components";
import Button from "@/atoms/Button";
import Icon from "@/atoms/Icon";
import { MouseEventHandler, useCallback, useContext, useEffect } from "react";
import { AdminContext } from "contexts/AdminProvider";
import { SocialLoginToken } from "lib/api/auth";
import { useToasts } from "react-toast-notifications";
import { formatToast } from "utils";

const win = global?.window as any;

interface CustomButtonProps {
  width: number;
}

const CustomButton = styled(Button)<CustomButtonProps>`
  background: #1877f2 !important;
  color: #fff !important;
  height: 40px;
  font-size: 14px;
  width: 100%;
  max-width: 100%;
`;
export interface FacebookLoginButton {
  onToken: (data: SocialLoginToken) => any;
  onError?: (data: any) => any;
  onClick?: (provider: string) => void;
  width?: number;
  renderButton?: (props: { onClick: MouseEventHandler }) => any;
}
const FacebookLoginButton = ({
  onToken,
  onError,
  renderButton,
  width = 260,
  ...props
}: FacebookLoginButton) => {
  const {
    settings: { FACEBOOK_OAUTH_CLIENT_ID },
  } = useContext(AdminContext);
  const { addToast } = useToasts();

  const label = (
    <div className={"flex items-center justify-center"}>
      <Icon
        name={"facebook"}
        size={"1.1rem"}
        className={"mr-3"}
        fill={"#fff"}
      />
      Continue with Facebook
    </div>
  );

  const initFacebookSdk = useCallback(() => {
    // wait for facebook sdk to initialize before starting the react app
    win.fbAsyncInit = function () {
      win.FB.init({
        appId: FACEBOOK_OAUTH_CLIENT_ID,
        cookie: true,
        xfbml: true,
        version: "v12.0",
      });
    };

    // load facebook sdk script
    (function (d, s, id) {
      if (!FACEBOOK_OAUTH_CLIENT_ID) {
        return;
      }
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      const js: any = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode?.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, [FACEBOOK_OAUTH_CLIENT_ID]);

  useEffect(() => {
    initFacebookSdk();
  }, [initFacebookSdk]);

  const handleAccessToken = (token: string) =>
    onToken({ token, provider: "facebook" });

  const getToken = () => {
    props.onClick?.("facebook");
    win.FB.getLoginStatus(function (response: any) {
      if (response.status === "connected") {
        // The user is logged in and has authenticated your
        // app, and response.authResponse supplies
        // the user's ID, a valid access token, a signed
        // request, and the time the access token
        // and signed request each expire.
        const accessToken = response.authResponse.accessToken;
        return handleAccessToken(accessToken);
      } else {
        return login();
      }
    });
  };

  const login = () => {
    win.FB.login(
      function (response: any) {
        if (response.status === "connected") {
          const accessToken = response.authResponse.accessToken;
          return handleAccessToken(accessToken);
        } else {
          if (onError) {
            onError(response);
          } else {
            addToast(
              ...formatToast(
                "There was an error logging into your Facebook account",
                {
                  appearance: "error",
                }
              )
            );
          }
        }
      },
      { scope: "public_profile, email" }
    );
  };

  return renderButton ? (
    renderButton({ onClick: getToken })
  ) : (
    <CustomButton
      label={label}
      width={width}
      level={"primary"}
      {...props}
      onClick={getToken}
    />
  );
};

export default FacebookLoginButton;
