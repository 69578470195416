import { useContext, useState } from "react";
import styled from "styled-components";
import analytics from "analytics";
import Icon from "@/atoms/Icon";
import { AuthContext } from "contexts/AuthProvider";
import {
  ConnectAccountForm,
  FacebookLoginButton,
  GoogleLoginButton,
} from "components/authenticate";
import ConnectAccountFormDialog from "components/authenticate/ConnectAccountFormDialog";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

const BackButton = styled.button`
  color: ${({ theme }) => theme.colors.perriwinkle};
  margin-bottom: 1.5rem;
  font-size: 0.875rem;
  font-weight: 700;
`;

type HandlerFunction = () => void;

const onClick = (provider: string) => {
  analytics.trackAmplitudeClickEvent("Social Login", provider);
};

export interface SocialButtonsInterface {
  className?: string;
  connectAccountUXType?: "inline" | "dialog";
  disableAutoTap?: boolean;
  hasDummyUser?: boolean;
  onConnectAccountCancel?: HandlerFunction;
  onConnectAccountForm?: HandlerFunction;
  onSuccess?: HandlerFunction;
  width?: number;
}

export const SocialButtonsContainer = ({
  className,
  connectAccountUXType = "inline",
  disableAutoTap = false,
  hasDummyUser = false,
  onConnectAccountCancel,
  onConnectAccountForm,
  onSuccess,
  width,
}: SocialButtonsInterface) => {
  const [providerData, setProviderData] = useState(null);
  const onToken = async ({
    token,
    provider,
  }: {
    token: string;
    provider: string;
  }) => {
    const res = await loginWithSocial({
      token,
      provider,
      onSuccess,
    });
    if (res && "providerData" in res) {
      const email = res.providerData.email;
      const { providerData } = res;
      // This account isn't associated with an account already,
      // link it to the active dummy account
      if (!email && hasDummyUser) {
        await linkProvider({ providerData });
        await loginWithSocial({
          token,
          provider,
          onSuccess,
        });
      } else {
        setProviderData(res.providerData);
        onConnectAccountForm?.();
      }
    }
  };

  const cancelAccountConnect = () => {
    setProviderData(null);
    onConnectAccountCancel?.();
  };

  const { loginWithSocial, loginWithPassword, linkProvider } =
    useContext(AuthContext);

  if (providerData) {
    const connectAccountFormProps = {
      providerData,
      onSubmit: loginWithPassword,
      onSuccess: () => linkProvider({ providerData, onSuccess }),
    };

    return connectAccountUXType === "dialog" ? (
      <ConnectAccountFormDialog open {...connectAccountFormProps} />
    ) : (
      <Container className={className}>
        <BackButton onClick={cancelAccountConnect}>
          <Icon
            name={"chevron-right"}
            size={"0.5rem"}
            className={"transform -rotate-180 mr-2"}
          />
          {"Back to Log In"}
        </BackButton>
        <ConnectAccountForm {...connectAccountFormProps} />
      </Container>
    );
  } else {
    return (
      <Container className={className}>
        <GoogleLoginButton
          onToken={onToken}
          disableAutoTap={disableAutoTap}
          width={width}
          onClick={onClick}
        />
        <FacebookLoginButton
          onToken={onToken}
          width={width}
          onClick={onClick}
        />
      </Container>
    );
  }
};
export default SocialButtonsContainer;
