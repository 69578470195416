import styled from "styled-components";
import Icon from "@/atoms/Icon";
import Divider from "@/atoms/account/Divider";
import { capitalize } from "lodash";
import {
  FormikForm,
  FormikSubmit,
  FormikTextField,
} from "@/atoms/account/formik";
import * as Yup from "yup";
import LegacyThemeProvider from "components/Layout/DefaultThemeProvider";
import { makeUrl } from "utils";
import { LINKS } from "globalConfig";
import { ParsedErrorResponse } from "lib/api/base";

export const FORM_ID = "connect-account-form";

const Title = styled.h1`
  font-size: 22px;
  line-height: 33px;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #232323;
`;

const CopyBlock = styled.div`
  color: #232323;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;

  p {
    line-height: 21px;
  }
`;

const Email = styled.div`
  font-weight: bold;
  font-size: 18px;
`;

const validationSchema = Yup.object().shape({
  login: Yup.string().required("Required."),
  password: Yup.string().required("Required."),
});

export interface ConnectAccountFormProps {
  className?: string;
  providerData: {
    provider: "facebook" | "google";
    email: string;
  };
  onSubmit: (data: {
    login: string;
    password: string;
  }) => Promise<object | ParsedErrorResponse>;
  onSuccess: () => void;
}

const ConnectAccountForm = ({
  providerData,
  className = "",
  onSubmit,
  onSuccess,
}: ConnectAccountFormProps) => {
  const { provider, email } = providerData;

  const initialValues = {
    login: email || "",
    password: "",
  };

  return (
    <LegacyThemeProvider>
      <div className={className}>
        <Title>
          <Icon name={provider} size={"1.5rem"} className={"mr-3"} />
          Connect Your Account
        </Title>
        <Divider className={"mt-2 mb-6"} />
        <CopyBlock className={"mb-4"}>
          {email ? (
            <CopyBlock>
              <div>Welcome,</div>
              <Email>{email}</Email>
              <p className={"mt-4"}>
                Since it’s your first time connecting your{" "}
                {capitalize(provider)} account to your Literati account, we need
                to confirm it’s you.
              </p>
            </CopyBlock>
          ) : (
            <CopyBlock>
              <p>We don’t have a Literati account that matches that email.</p>
              <div className={"mt-4 font-bold"}>Log in to Literati</div>
              <p>
                We’ll confirm it’s you and connect your {capitalize(provider)}{" "}
                account to your Literati account.
              </p>
            </CopyBlock>
          )}
        </CopyBlock>
        <FormikForm
          id={FORM_ID}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSuccess={onSuccess}
        >
          {!email && (
            <FormikTextField
              name={"login"}
              type={"email"}
              label={"Email Address"}
            />
          )}
          <FormikTextField
            name={"password"}
            type={"password"}
            label={email ? "Enter your Literati password" : "Password"}
            placeholder={"Enter your password"}
          />
          <FormikSubmit
            className={"mt-4 w-full"}
            label={"Log In"}
            form={FORM_ID}
          />
          <a
            className={
              "text-perriwinkle text-sm font-bold block text-center w-full mt-4"
            }
            href={makeUrl(LINKS.resetPassword)}
          >
            Forgot Password?
          </a>
        </FormikForm>
      </div>
    </LegacyThemeProvider>
  );
};

export default ConnectAccountForm;
